import axios from "axios";
import { NewsCard } from "components/news_card/news_card";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./news_list.scss";
import { Loader } from "components/loader/loader";

export const NewsList = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState("Something went wrong");

  const getNewsOfPage = (page) => {
    setError("");
    setIsLoading(true);
    axios
      .get(`https://paysprint.ca/api/v1/investorsnews?page=${page}`, {
        headers: {
          Authorization:
            "Bearer base64:JFM+PJaWD/pBypX+NhXudDrAmianZdGYZ41qz4WhXL0=",
        },
      })
      .then((resp) => {
        setCurrPage(resp.data.data.current_page);
        setTotalPages(resp.data.data.last_page);
        setNews(resp.data.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      });
  };

  useEffect(() => {
    getNewsOfPage(1);
  }, []);

  return (
    <section className="newsList">
      <h3>Our Latest News</h3>
      {error ? (
        <div className="error">
          <h3>{error}</h3>
          <button onClick={() => getNewsOfPage(currPage || 1)}>Reload</button>
        </div>
      ) : isLoading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : news.length ? (
        <div className="container">
          {news.map((iNews, idx) => (
            <NewsCard
              key={iNews.id}
              title={iNews.title}
              date={new Date(iNews.created_at)}
              pageUrl={`/blog/${iNews.id}`}
              imgUrl={iNews.file}
            />
          ))}
        </div>
      ) : (
        <></>
      )}

      {totalPages ? (
        <div className="pagination">
          <button
            className="left"
            onClick={() => getNewsOfPage(currPage - 1)}
            disabled={currPage === 1}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          {new Array(totalPages).fill(0).map((_, idx) => (
            <button
              key={idx}
              disabled={currPage === idx + 1}
              onClick={() => getNewsOfPage(idx + 1)}
              className={`${currPage === idx + 1 ? "active" : ""}`}
            >
              {idx + 1}
            </button>
          ))}
          <button
            className="right"
            onClick={() => getNewsOfPage(currPage + 1)}
            disabled={currPage === totalPages}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};
