import { BlogPage } from "pages/blog/blog";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/home/home";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/blog/:blogId" element={<BlogPage />} />
    </Routes>
  );
}

export default App;
