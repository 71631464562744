import { useState } from "react";
import { URLS } from "utils/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./header.scss";
import { Link } from "react-router-dom";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInvestorMenuOpen, setIsInvestorMenuOpen] = useState(false);

  const toggleIsMenuOpen = (e) => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleInvestorMenu = () => {
    setIsInvestorMenuOpen(!isInvestorMenuOpen);
  };

  return (
    <>
      <div className="mobile-nav">
        <header>
          <Link to="/">
            <img
              className="logo"
              src={URLS.paySprintLogo}
              alt="paysprint logo"
            />
          </Link>

          <button onClick={toggleIsMenuOpen}>
            <span className={`first ${isMenuOpen ? "open" : ""}`} />
            <span className={`second ${isMenuOpen ? "open" : ""}`} />
            <span className={`third ${isMenuOpen ? "open" : ""}`} />
          </button>
        </header>
        <nav className={`menu ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/"> HOME </Link>
            </li>
            <li>
              <a href="https://paysprint.ca/about">ABOUT</a>
            </li>
            <li>
              <Link to="/">
                {" "}
                INVESTORS NEWS
                <FontAwesomeIcon
                  onClick={toggleInvestorMenu}
                  icon={isInvestorMenuOpen ? faAngleUp : faAngleDown}
                />{" "}
              </Link>
            </li>

            <li className={`submenu ${isInvestorMenuOpen ? "open" : ""}`}>
              <a href="https://investor.paysprint.ca/login.html">
                SIGNUP/LOGIN
              </a>
            </li>
            <li>
              <a href="https://paysprint.ca/contact">CONTACT US</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="desktop-nav">
        <div className="left">
          <Link to="/">
            <img src={URLS.paySprintLogo} alt="paysprint logo" />
          </Link>
        </div>
        <div className="right">
          <nav>
            <ul>
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <a href="https://paysprint.ca/about">ABOUT</a>
              </li>
              <li className="investor">
                <Link to="/">INVESTORS NEWS </Link>
                <FontAwesomeIcon
                  onClick={toggleInvestorMenu}
                  icon={faAngleDown}
                />
                <div className="subMenu">
                  <a href="https://investor.paysprint.ca/login.html">
                    SIGNUP/LOGIN
                  </a>
                </div>
              </li>
              <li>
                <a href="https://paysprint.ca/contact">CONTACT US</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
