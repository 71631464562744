import { Loader } from "components/loader/loader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "utils/urls";
import "./news_card.scss";

export const NewsCard = ({ imgUrl, title, date, pageUrl }) => {
  const navigate = useNavigate();
  const [blogImageUrl, setBlogImageUrl] = useState();

  useEffect(() => {
    console.log(imgUrl);
    checkIfImageExists(imgUrl);
  }, [imgUrl]);

  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      setBlogImageUrl(url);
    } else {
      img.onload = () => {
        setBlogImageUrl(url);
      };

      img.onerror = () => {
        setBlogImageUrl(URLS.paySprintLogo);
      };
    }
  }
  return (
    <div
      className="newsCard"
      onClick={() => {
        navigate(pageUrl);
      }}
    >
      {blogImageUrl ? (
        <img src={blogImageUrl} alt="news info" />
      ) : (
        <div className="loader-container">
          <Loader size={40} />
        </div>
      )}
      <div className="info">
        <h4>{title}</h4>
        <em>{date.toDateString()}</em>
      </div>
    </div>
  );
};
